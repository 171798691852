<template>
  <div v-loading="loading" style="margin-left: 35px">
    <div v-if="!loading">
      <!-- <div v-if="!isCurrentSlugPresent">Invalid step</div> -->
      <div v-if="isCurrentSlugPresent">
        <div v-if="getCurrentStep">
          <PreviewTemplate
            :templateData="getCurrentStep.templateInfo"
            :has-next="getCurrentStep.has_next"
            :entityDataId="entityDataId"
            :is-execute="false"
            :is-edit="false"
          />
        </div>
      </div>

      <div v-else-if="isCurrentSlugIsRelationship">
        <EntityReationshipDataTable
          :relationshipDetails="getCurrentRelationshipDetails"
          :nextRealationshipDetails="hasNextRelationshipDetails"
        />
      </div>

      <div v-else-if="isCurrentSlugIsNestedRelationship">
        <EntityNestedReationshipDataTable
          :relationshipDetails="getCurrentNestedRelationshipDetails"
          :nextRealationshipDetails="null"
        />
      </div>

      <div v-else>
        <div
          v-if="
            templateKey === 'contacts' &&
            getEntityDataById.entity_type != 'INDIVIDUAL'
          "
        >
          <EntityContacts
            :contactType="entityId"
            :accountName="entityDataId"
            :entityDataId="getEntityDataById"
          />
        </div>

        <div v-else style="text-align: center; color: red">
          <h3>Invalid step</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewTemplate from "./../templates/PreviewTemplate";
import EntityContacts from "./EntityContacts.vue";
import { mapGetters } from "vuex";
import EntityReationshipDataTable from "./EntityReationshipDataTable.vue";
import EntityNestedReationshipDataTable from "./EntityNestedReationshipDataTable.vue";

export default {
  name: "Entity-PreviewStep",
  components: {
    PreviewTemplate,
    EntityContacts,
    EntityReationshipDataTable,
    EntityNestedReationshipDataTable,
  },
  data() {
    return {
      currentSlug: null,
      loading: false,
      entityDataId: null,
      templateKey: null,
      entityId: null,
      currentRelationshipDetails: {},
      currentEntityNestedRelationships: {},
      currentRelationshipIndex: -1,
      relationshipsData: [],
      hasNextRelationshipDetails: null,
    };
  },
  mounted() {
    this.getNecessaryInfo();
  },
  computed: {
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getCurrentEntityNestedRelationships",
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    slugs() {
      if (this.getEntityDataById && this.getEntityDataById.templates) {
        return this.getEntityDataById.templates.map(
          (step) => step.templateInfo.slug
        );
      }
      return [];
    },
    isCurrentSlugPresent() {
      if (this.slugs && this.slugs.length) {
        if (this.slugs.indexOf(this.currentSlug) === -1) {
          return false;
        }
        return true;
      }
      return true;
    },

    isCurrentSlugIsRelationship() {
      let relationship = this.$route.query.relationship_id;
      return (
        relationship &&
        this.currentRelationshipDetails &&
        this.currentRelationshipDetails.relationship_id == relationship
      );
    },

    isCurrentSlugIsNestedRelationship() {
      let relationship = this.$route.query.relationship_id;
      return (
        relationship &&
        this.currentEntityNestedRelationships &&
        this.currentEntityNestedRelationships.relationship_id == relationship
      );
    },
    getStepsObject() {
      if (this.getEntityDataById && this.getEntityDataById.templates) {
        const data = {};
        this.getEntityDataById.templates.forEach((step, index, arr) => {
          // it will use to determine finish step
          step.has_next = index < arr.length - 1;
          data[step.templateInfo.slug] = step;
        });

        return data;
      }
      return {};
    },
    getCurrentStep() {
      if (this.getStepsObject && this.currentSlug) {
        const step = this.getStepsObject[this.currentSlug];
        return step;
      }

      return null;
    },
    getCurrentRelationshipDetails() {
      return this.currentRelationshipDetails;
    },
    getCurrentNestedRelationshipDetails() {
      return this.currentEntityNestedRelationships;
    },
  },
  methods: {
    async getNecessaryInfo() {
      this.templateKey = this.$route.query.templateKey || null;
      this.entityDataId = this.$route.query.dataId || null;
      this.entityId = this.$route.params.entity_id;
      this.currentSlug = this.$route.params.template_code;
      if (
        !this.getEntityDataById ||
        Object.keys(this.getEntityDataById).length === 0
      ) {
        this.loading = true;
        this.step = this.$route.query.step || 1;
        this.loading = false;
      }
    },
  },
  watch: {
    "$route.params.template_code": {
      handler: function (slug) {
        this.currentSlug = slug;
        if (this.$route.query.relationship_id) {
          this.currentRelationshipIndex = [
            ...this.getCurrentEntityRelationships,
          ].findIndex((e) => e.relationship_slug == slug);

          this.currentRelationshipDetails = [
            ...this.getCurrentEntityRelationships,
          ].find((e) => e.relationship_slug == slug);

          this.currentEntityNestedRelationships = [
            ...this.getCurrentEntityNestedRelationships,
          ].find((e) => e.relationship_slug == slug);

          if (
            this.currentRelationshipIndex > -1 &&
            this.currentRelationshipIndex + 1 <=
              this.getCurrentEntityRelationships.length - 1
          ) {
            this.hasNextRelationshipDetails =
              this.getCurrentEntityRelationships[
                this.currentRelationshipIndex + 1
              ];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style></style>
